import { HttpService, RequestTools } from '../libs/request-tools';

const RequestsProvider = {
    /**
     * Retrieve zip codes
     * @returns {Promise<any>}
     */
    getEthTransactions: async (): Promise<any> => {
        return new Promise((resolve, reject) => {
            const params = {
                module: 'account',
                action: 'txlist',
                address: process.env.REACT_APP_ETH_CONTRACT,
                startblock: 0,
                endblock: 99999999,
                page: 1,
                sort: 'asc',
                apikey: process.env.REACT_APP_ETH_API_TOKEN
            };

            HttpService.get(process.env.REACT_APP_ETH_URL, { params }).then((res: any) => {
                resolve(res.data);
            }).catch((e: any) => {
                reject(RequestTools.formatError(e));
            });
        });
    },

    getMetadata: async(url: string): Promise<any> => {
        return new Promise((resolve, reject) => {
            // Add proxy if cors problem https://proxy.sunland.finance/
            HttpService.get(`${url}`).then((res: any) => {
                resolve(res.data);
            }).catch((e: any) => {
                reject(RequestTools.formatError(e));
            });
        });
    }
};

export default RequestsProvider;
