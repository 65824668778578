import React, {useState, useEffect, useMemo, useContext} from 'react';
import './globe-world.scss';
import Globe from 'react-globe.gl';
import Loader from "../loader/loader";
import bgImg from '../../assets/night-sky.png';
import MetamaskButton from "../metamask/metamask";
import MessageContext from "../../contexts/message";
import GlobalContext from "../../contexts/global";
import MintButton from "../MintButton/mint-button";
import {TokenOwnerI} from "../../types";
import RequestsProvider from "../../providers/requests";
import stc from 'string-to-color';
import {Tools} from "../../libs/tools";
import MintingReward from "../minting-reward/minting-reward";
import Color from 'color';



const GlobeWorld = ({ globalData } : any) => {
    const messageContext: any = useContext(MessageContext);
    const globalContext: any = useContext(GlobalContext);

    const [countries, setCountries] = useState<any>(null);
    const [hoverD, setHoverD] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [tokenOwnerMetadata, setTokenOwnerMetadata] = useState<any>([]);
    const [newMetadata, setNewMetadata] = useState<any>();
    const [loadingMintingReward, setLoadingMintingReward] = useState<boolean>();
    const [showLand, setShowLand] = useState<boolean>(false);

    const loadData = async() => {
        const dataFetch = await fetch('countries.geojson');
        const response = await dataFetch.json();
        setCountries(response);
    };

    /**
     * Search for land and color it depending owner
     * @param mapData
     */
    const colorLand = (mapData: any): any => {
        const tokOwns = globalData.tokensOwner;
        // return Color(stc(mapData.properties.NAME)).alpha(1).lighten(0.3).hex();

        if (tokOwns && tokenOwnerMetadata) {
            const currentMetadata = tokenOwnerMetadata.find((tokOwnerMeta: any) =>
                    tokOwnerMeta.attributes
                        .find((att: any) => att.value === mapData.properties.NAME));

            if (currentMetadata) {
                const owner: TokenOwnerI = tokOwns.find((i: any) => i.idToken === currentMetadata.idToken);
                return Color(stc(owner.address)).alpha(1).lighten(0.3).hex();
            }

            return '#fff';
        }
    };

    const fetchTokenOwnerMetadata = async () => {
        const tokOwns = globalData.tokensOwner;

        const fetchedData = await Promise.all(
            tokOwns.map((i: any) => RequestsProvider.getMetadata(i.url))
        );
        setTokenOwnerMetadata(fetchedData);
    };

    const onMint = async (tokenId: string) => {
        const url = await globalContext.contract.methods.baseURI().call();
        const metadata = await RequestsProvider.getMetadata(url + tokenId);
        setNewMetadata(metadata);

        const tokOwners = await globalContext.contract.methods.getAllOwnerAndToken().call();
        if (url && tokOwners) {
            const formattedTokenOwners: (TokenOwnerI | null)[] = Tools.formatTokenOwners(tokOwners, url);
            if (formattedTokenOwners) {
                globalContext.setTokensOwner(formattedTokenOwners);
            }
        }
    };

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (countries) {
            setTimeout(() => {
                setLoading(true);
                globalContext.init();
            }, 1500);
        }
    }, [countries]);

    useEffect(() => {
        if (globalData.tokensOwner) {
            fetchTokenOwnerMetadata();
        }
    }, [globalData.tokensOwner]);

    const onLoad = (value: boolean) => {
        setLoadingMintingReward(value);
    };

    const onClose = () => {
        setLoadingMintingReward(false);
        setNewMetadata(null);
    };

    const displayField = (field: string, meta: any) => {
        const data = meta.attributes
            .find((i: any) => i.trait_type === field);
        if (data) {
            return data.value;
        }
        return '';
    };

    const displayCountryPop = (data: any) => {
        const tokOwns = globalData.tokensOwner;

        if (tokOwns && tokenOwnerMetadata) {
            const currentMetadata = tokenOwnerMetadata.find((tokOwnerMeta: any) =>
                tokOwnerMeta.attributes
                    .find((att: any) => att.value === data.properties.NAME));


            if (currentMetadata) {
                const title = displayField('Country', currentMetadata);
                const capital = displayField('Capital', currentMetadata);
                const iso = displayField('ISO_A2', currentMetadata);
                const pop = displayField('Population', currentMetadata);
                const pib = displayField('GDP', currentMetadata);
                const owner: TokenOwnerI = tokOwns.find((i: any) => i.idToken === currentMetadata.idToken);

                return `
                <div class="country-popup">
                    <div class="title-cont">
                         <img class="title-img" src="${currentMetadata.image}" alt="logo_icon">
                         <span class="title">${title}</span>
                    </div>
                    
                    <div class="country-infos">
                        <p>
                            <span class="field-title">King :</span>
                            <span class="field-value">${owner.address}</span>
                        </p>
                         <p>
                            <span class="field-title">Farmers :</span>
                            <span class="field-value">0</span>
                        </p>
                         <p>
                            <span class="field-title">Monks :</span>
                            <span class="field-value">0</span>
                        </p>
                        <p>
                            <span class="field-title">Warriors :</span>
                            <span class="field-value">0</span>
                        </p>
                    
                    </div>
               
                </div>
            `
            }
        };
        return '';
    }

    const elevateLand = (d: any) => {
        if (showLand) {
            const tokOwns = globalData.tokensOwner;
            const currentMetadata = tokenOwnerMetadata.find((tokOwnerMeta: any) =>
                tokOwnerMeta.attributes
                    .find((att: any) => att.value === d.properties.NAME));
            if (currentMetadata) {
                const owner: TokenOwnerI = tokOwns.find((i: any) => i.idToken === currentMetadata.idToken);
                if (owner.address === globalContext.account) {
                    return 0.15;
                }
            }
        }

        return d === hoverD ? 0.12 : 0.06
    };

    return (
        <div>
            {
                loading ? (
                    <>
                        { globalContext.ethereum ? <MetamaskButton /> : null }

                        { globalContext.account && globalContext.ethereum && globalContext.contract ? <MintButton onMint={onMint} onLoad={onLoad}/> : null }

                        <MintingReward
                            onClose={onClose}
                            metadata={newMetadata}
                            loadingMintingReward={loadingMintingReward}/>

                            <div className={`show-my-lands ${showLand ? 'active' : ''}`} onClick={() => setShowLand(!showLand)}>
                                <p>Show my lands</p>
                            </div>

                        <Globe
                            globeImageUrl="./earth-night.jpg"
                            backgroundImageUrl={bgImg}
                            lineHoverPrecision={0}
                            polygonsData={countries?.features.filter((d: any) => d.properties.ISO_A2 !== 'AQ')}
                            polygonAltitude={elevateLand}
                            polygonCapColor={colorLand}
                            polygonSideColor={() => 'rgba(0, 100, 0, 0.15)'}
                            polygonStrokeColor={() => '#111'}
                            // @ts-ignore
                            polygonLabel={displayCountryPop}
                            onPolygonHover={setHoverD}
                            polygonsTransitionDuration={300}
                            polygonCapCurvatureResolution={20}
                            pathResolution={20}
                            hexTopCurvatureResolution={20}
                            hexBinMerge={true}
                        />
                    </>
                ) : <Loader display={true}/>
            }
        </div>
    )
};


export default GlobeWorld;
