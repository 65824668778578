import React from 'react';
import './App.scss';
import GlobeWorld from './views/globe-world/globe-world';
import MessageContext , { MessageProvider } from './contexts/message';
import Popup from './views/popup/popup';
import GlobalContext, { GlobalProvider } from './contexts/global';
import { GlobalDataI, PopupI } from "./types";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from 'react-router-dom';

const App = () => {
  return (
      <Router>
          <Switch>
              <Route path="/" exact>
                  <div className="App">
                      <MessageProvider>
                          <GlobalProvider>
                              <GlobalContext.Consumer>
                                  {
                                      (globalData?: GlobalDataI) => {
                                          return (
                                              <MessageContext.Consumer>
                                                  {
                                                      ({ popupData } : { popupData?: PopupI }): any => {
                                                          return (
                                                              <div>
                                                                  <Popup popupData={popupData}/>
                                                                  <GlobeWorld globalData={globalData}/>
                                                              </div>
                                                          );
                                                      }
                                                  }
                                              </MessageContext.Consumer>
                                          );
                                      }
                                  }
                              </GlobalContext.Consumer>
                          </GlobalProvider>
                      </MessageProvider>
                  </div>
              </Route>
          </Switch>
      </Router>
  )
};

export default App;
