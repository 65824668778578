import React, { useState } from 'react';
import { PopupI } from "../types";

const MessageContext = React.createContext({});


export const MessageProvider = (props: any) => {
    const [popupData, setPopupData] = useState<PopupI>({});

    return (
        <MessageContext.Provider value={{
            setPopupData,
            popupData
        }}
        >
            { props.children }
        </MessageContext.Provider>
    );
};

export default MessageContext;
