import React, {useContext, useEffect} from 'react';
import { PopupI } from "../../types";
import './popup.scss';
import MessageContext from "../../contexts/message";


const Popup = ({ popupData } : { popupData?: PopupI }) => {
    const messageContext: any = useContext(MessageContext);

    useEffect(() => {
        if (popupData && popupData.display) {
            setTimeout(() => {
                messageContext.setPopupData({
                    display: false
                });
            }, popupData.delay);
        }
    }, [popupData]);

    return (
        <div className={`message ${popupData?.display ? 'show' : ''} ${popupData?.severity}`}>
            { popupData?.message }
        </div>
    );
};

export default Popup;
