import React, { useState, useEffect, useContext } from "react";
import MessageContext from "./message";
import Web3 from "web3";
import { TokenOwnerI } from "../types";
import { Tools } from "../libs/tools";

const abi: any = [];

const GlobalContext = React.createContext({});

export const GlobalProvider = (props: any) => {
  const messageContext: any = useContext(MessageContext);

  const [ethereum, setEthereum] = useState<any>(null);
  const [account, setAccount] = useState<any>(null);
  const [web3, setWeb3] = useState<any>(null);
  const [contract, setContract] = useState<any>(null);
  const [tokensOwner, setTokensOwner] = useState<any>(null);

  const init = async () => {
    if (typeof (window as any).ethereum !== "undefined") {
      const eth = (window as any).ethereum;
      setEthereum(eth);
      if (eth) {
        (window as any).web3 = new Web3(eth);
        setWeb3((window as any).web3);
        await eth.enable();
      } else if ((window as any).web3) {
        (window as any).web3 = new Web3((window as any).web3.currentProvider);
        setWeb3((window as any).web3);
      } else {
        messageContext.setPopupData({
          display: true,
          message: "MetaMask not detected ! Please install MetaMask.",
          severity: "warn",
          delay: 5000,
        });
      }
    } else {
      messageContext.setPopupData({
        display: true,
        message: "MetaMask not detected ! Please install MetaMask.",
        severity: "warn",
        delay: 5000,
      });
    }
  };

  const getAccount = async () => {
    const accounts = await web3.eth.getAccounts();
    if (accounts && accounts[0]) {
      setAccount(accounts[0]);
      return accounts[0];
    }
  };

  const initContract = async () => {
    messageContext.setPopupData({
      display: true,
      message:
        "Sunland App available only with GOERLI NETWORK for the moment ! Redirecting in 5 seconds",
      severity: "warn",
      delay: 5000,
    });

    setTimeout(() => {
      (window.location as any) = "https://testnet-app.sunland.finance";
    }, 5000);
  };

  const onChangeAccount = (accounts: any) => {
    setAccount(accounts[0]);
  };

  const initAccount = async () => {
    await getAccount();
  };

  useEffect(() => {
    if (ethereum) {
      ethereum.on("accountsChanged", onChangeAccount);
    }
  }, [ethereum]);

  useEffect(() => {
    if (
      web3 &&
      web3.eth &&
      account &&
      process.env.REACT_APP_CONTRACT !== "test"
    ) {
      initContract();
    }

    if (web3 && web3.eth && !account) {
      initAccount();
    }
  }, [web3, account]);

  return (
    <GlobalContext.Provider
      value={{
        ethereum,
        account,
        web3,
        contract,
        setAccount,
        init,
        tokensOwner,
        setTokensOwner,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
