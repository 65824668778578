import React, {useContext, useEffect, useState} from 'react';
import './minting-reward.scss';
import MessageContext from "../../contexts/message";
import GlobalContext from "../../contexts/global";
import Loader from './../../assets/8555-loading.json';
import Lottie from "react-lottie";

const MintingReward = ({ metadata, loadingMintingReward, onClose } : any) => {
    const messageContext: any = useContext(MessageContext);
    const globalContext: any = useContext(GlobalContext);

    const [display, setDisplay] = useState<boolean>(false);

    const displayField = (field: string) => {
        const data = metadata.attributes
            .find((i: any) => i.trait_type === field);
        if (data) {
            return data.value;
        }
        return '';
    };

    const close = () => {
        onClose();
        setDisplay(false);
    }

    useEffect(() => {
        if (loadingMintingReward) {
            setDisplay(true);
        }
    }, [loadingMintingReward]);


    const getView = () => {
        if (!loadingMintingReward && display && metadata) {
            return (
                <div className="minting-reward-container">
                    <div className="minting-reward-popup">
                        <p className="minting-reward-title">Congratulations !</p>
                        <p className="minting-reward-description">You are now the King of <span>{ displayField('Country') }</span></p>

                        <img src={metadata.image} alt="flag_image" />

                        <button onClick={close}>Close</button>
                    </div>
                </div>
            );
        }

        if ( display && !metadata && loadingMintingReward) {
            return (
                <div className="minting-reward-container">
                    <div className="minting-reward-popup">
                        <p className="minting-reward-title">Looking for an available land !</p>
                        <Lottie options={{
                            loop: true,
                            autoplay: true,
                            animationData: Loader,
                        }}
                                height={100}
                                width={100}
                        />
                    </div>
                </div>
            );
        }

        return null;
    }

    return getView();
};

export default MintingReward;
