const axios = require('axios');

export const HttpService = axios.create({
    // baseURL: config.api_url,
    timeout: 30000
});

export const RequestTools = {

    /**
     * Format request error
     * @param error
     * @returns {{error: {data: string, status: number}}}
     */
    formatError: (error: any) => {
        if (error) {
            if (error.response && error.response.status && error.message) {
                return {
                    error:
                        {
                            status: error.response.status,
                            data: error.message
                        }
                };
            }
            return {
                error
            };
        }
        return null;
    }
};
