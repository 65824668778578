import React, {useContext, useEffect, useState} from 'react';
import './metamask.scss';
import Loader from './../../assets/loader.json';
import Lottie from "react-lottie";
import MessageContext from "../../contexts/message";
import GlobalContext from "../../contexts/global";


const MetamaskButton = (props: any) => {
    const messageContext: any = useContext(MessageContext);
    const globalContext: any = useContext(GlobalContext);

    const [loading, setLoading] = useState<boolean>(false);

    const connect = async () => {
        try {
            if (!loading) {
                setLoading(true);
                const accounts = await globalContext.ethereum.request({ method: 'eth_requestAccounts' });
                messageContext.setPopupData({
                    display: true,
                    message: `Connection success !`,
                    severity: 'success',
                    delay: 3000
                });
                globalContext.setAccount(accounts[0]);
                setLoading(false);
            }
        } catch (e) {
            messageContext.setPopupData({
                display: true,
                message: `Couldn't connect to your MetaMask Wallet. Please retry !`,
                severity: 'error',
                delay: 10000
            });
            setLoading(false);
        }
    };

    const displayLoginButton = () => (
        <div className={`metamask-button ${loading ? 'loading' : ''}`} onClick={connect}>
            {
                loading ? (
                    <>
                        <Lottie options={{
                            loop: true,
                            autoplay: true,
                            animationData: Loader,
                        }}
                                height={20}
                                width={40}
                        />
                    </>
                ) : (
                    <>
                        <p>Connect with MetaMask</p>
                        <img src="./metamask.png" alt="mm"/>
                    </>
                )
            }
        </div>
    );

    const displayAccountButton = () => (
        <div className={`metamask-button connected`}>
            { globalContext.account }
        </div>
    );

    return globalContext.account ? displayAccountButton() : displayLoginButton();
};

export default MetamaskButton;
