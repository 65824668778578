import React, {useContext, useEffect, useState} from 'react';
import './mint-button.scss';
import MessageContext from "../../contexts/message";
import GlobalContext from "../../contexts/global";


const MintButton = ({ onMint, onLoad } : any) => {
    const messageContext: any = useContext(MessageContext);
    const globalContext: any = useContext(GlobalContext);

    const [isSaleActive, setSaleActive] = useState<boolean>(false);

    const mint = async () => {
        onLoad(true);
        try {
            // Price gonna change
            const tokenMinted = await globalContext
                .contract.methods
                .mint()
                .send({
                    value: globalContext.web3.utils.toWei('1', 'ether')
                });
            onMint(tokenMinted.events.Transfer.returnValues.tokenId);
        } catch(e) {
            messageContext.setPopupData({
                display: true,
                message: `Error while minting ! Check that you have suffisant ETH in your wallet.`,
                severity: 'error',
                delay: 5000
            });
        }
        onLoad(false);
    };

    const checkSaleActive = async() => {
        if (process.env.REACT_APP_CONTRACT !== 'test') {
            const isActive = await globalContext
                .contract
                .methods
                .isSaleActive()
                .call();
            setSaleActive(isActive);
        }
    };

    useEffect(() => {
        if (globalContext.account
            && globalContext.ethereum
            && globalContext.contract) {
            checkSaleActive();
        }
    }, [
        globalContext.account,
        globalContext.ethereum,
        globalContext.contract]);

    return (
        <div className="button-mint-container">
            <button
                type="button"
                className={`button-mint`}
                disabled={!isSaleActive}
                onClick={mint}>
                Become a King
            </button>
        </div>
    );
};

export default MintButton;
