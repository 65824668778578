import React from 'react';
import './loader.scss';
import Lottie from 'react-lottie';
import load from '../../assets/8555-loading.json';


const Loader = ({ display } : { display: boolean }) => {

    return display ? (
        <div className="loader-container">
            <Lottie options={{
                loop: true,
                autoplay: true,
                animationData: load,
            }}
                    height={200}
                    width={window.innerWidth > 768 ? 200 : 200}
            />
            <p>Loading lands</p>
        </div>
    ) : null;
};

export default Loader;
