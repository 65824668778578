import {TokenOwnerI} from "../types";

export const Tools = {

    /**
     * Format token owner
     * @param error
     * @returns {{error: {data: string, status: number}}}
     */
    formatTokenOwners: (tokenOwner: string[], url: string): (null | TokenOwnerI)[] => {
        if (tokenOwner) {
            return tokenOwner.map((item, index) => {
                if (index === 0) return null;

                const concat = '' + url + index;
                const tok: TokenOwnerI = {
                    idToken: index,
                    url: concat,
                    address: item
                };
                return tok;
            }).filter(i => i);
        }
        return []
    }
};
